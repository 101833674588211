.gmap_container {
    position: relative;
    display: flex;
    place-content: center;
}

.gmap {
    position: absolute;
    bottom: 73px;
    right: 0px;
    border-radius: 15px;
    height: 280px;
    width: 100%;
    overflow: hidden;
    z-index: 77;
}

.pac-container {
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    border: 1px solid #f0f1f5;
    border-radius: 0.75rem;
    box-shadow: #959da533 0px 8px 24px;
}

.pac-item {
    border-color: $border;
    color: $dark;
    &:hover,
    &:focus,
    &:focus {
        box-shadow: none;
        border-color: $primary;
        color: $dark;
    }
}
